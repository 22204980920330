import {openDB} from 'idb';
import md5 from 'md5';

import {MailFieldsForCache, parseMessageForCache} from "../mail/utils";
import {CACHE_CONF, IDB_VERSION} from "../utils/constants";
import StateManager from "../redux/StateManager";
import {listingForCache, markDeviceSetupComplete} from "../server-calls";
import _ from "lodash";
import {initializeSocket} from "../socket";

export async function startCache() {
    let mailboxes = StateManager.cacheMailData().boxes
    await initMailsCacheDB(mailboxes)
    if (StateManager.cacheMailData().device.IsSetupComplete) {
        console.log(`Setup is completed, handling socket now.`)
        handleSocket()
        return
    }
    let body = CACHE_CONF
    for (let i = 0; i < mailboxes.length; i++) {
        let lastId = 0
        body.box = mailboxes[i].name
        let currentMailboxDone = false
        while (!currentMailboxDone) {
            try {
                body.lastId = lastId
                let mailData = await listingForCache(body, StateManager.getToken())
                if (_.isEmpty(mailData.mails)) {
                    currentMailboxDone = true
                    continue
                }
                let mails = mailData.mails
                for (let j = 0; j < mails.length; j++) {
                    let mailObject = mails[j]
                    await parseAndAddEmailInCache(mailboxes[i].name, mailObject)
                    if (j === mails.length - 1) {
                        lastId = mails[j].uid
                    }
                }
                if (mails.length < CACHE_CONF.setSize) {
                    currentMailboxDone = true
                }
            } catch (e) {
                currentMailboxDone = true
            }
        }
        console.log(`Cache done for ${mailboxes[i].name}`)
    }
    await markDeviceSetupComplete(StateManager.getToken())
    let cacheData = _.cloneDeep(StateManager.cacheMailData())
    cacheData.device.IsSetupComplete = true
    StateManager.setCacheMailData({})
    StateManager.setCacheMailData(cacheData)
    console.log(`All Done, for cache`)
    handleSocket()
}

export async function handleSocket() {
    if (StateManager.cacheMailData().device.IsSetupComplete) {
        let socket = initializeSocket()
        socket.on("message", (message) => {
            console.log(`Received push from backend.`)
            message = JSON.parse(message)
            updateCache(message)
        })
    }
}


async function localDbExists() {
    const dbName = md5(StateManager.getUserEmail())
    try {
        const db = await openDB(dbName);
        db.close();
        return true;
    } catch (error) {
        return false;
    }
}


export async function initMailsCacheDB(boxes) {
    const dbName = md5(StateManager.getUserEmail());
    const existingDatabases = await indexedDB.databases();
    const dbExists = existingDatabases.some((db) => db.name === dbName);
    if (dbExists) {
        console.log(`Database '${dbName}' already exists.`);
        return;
    }
    let db = await openDB(dbName, IDB_VERSION, {
        upgrade(db) {
            boxes.forEach((box) => {
                const boxName = md5(box.name);
                if (!db.objectStoreNames.contains(boxName)) {
                    let store = db.createObjectStore(boxName, {keyPath: MailFieldsForCache.uid});
                    store.createIndex(MailFieldsForCache.uid, MailFieldsForCache.uid, {unique: true});
                } else {
                    console.log(`Collection already added: ${boxName}`)
                }
            });
        }
    });
    console.log(`Mails local collections added.`)
    return db.close()
}

export async function addEmailInCache(box, message) {
    const dbName = md5(StateManager.getUserEmail())
    box = md5(box)
    const db = await openDB(dbName, IDB_VERSION);
    const tx = db.transaction(box, 'readwrite');
    const store = tx.objectStore(box);
    await store.put(message);
    await tx.done;
}

export async function countCachedEmailsBoxWise(boxes) {
    const dbName = md5(StateManager.getUserEmail())
    const db = await openDB(dbName, IDB_VERSION);
    const counts = {};
    for (let i = 0; i < boxes.length; i++) {
        try {
            let collectionName = md5(boxes[i].name)
            const tx = db.transaction(collectionName, 'readonly');
            const store = tx.objectStore(collectionName);
            counts[boxes[i].name] = await store.count()
            await tx.done;
        } catch (err) {
            console.error(err)
            counts[boxes[i].name] = 0
        }
    }
    return counts;
}

export async function countAllCachedEmails(boxes) {
    const dbName = md5(StateManager.getUserEmail())
    let boxWiseCount = await countCachedEmailsBoxWise(dbName, boxes);
    let total = 0
    for (let box in boxWiseCount) {
        total += boxWiseCount[box];
    }
    return {total, boxWiseCount}
}

export async function deleteMessageFromBox(box, uid) {
    const dbName = md5(StateManager.getUserEmail())
    box = md5(box)
    const db = await openDB(dbName, IDB_VERSION);
    const tx = db.transaction(box, 'readwrite');
    const store = tx.objectStore(box);
    await store.delete(uid);
    await tx.done;
}

export async function readMessageByUid(box, uid) {
    const dbName = md5(StateManager.getUserEmail())
    box = md5(box)
    const db = await openDB(dbName, IDB_VERSION);
    const tx = db.transaction(box, 'readonly');
    const store = tx.objectStore(box);
    const message = await store.get(uid);
    await tx.done;
    return message;
}

export async function moveMessageBetweenBoxes(sourceBox, targetBox, uid) {
    const dbName = md5(StateManager.getUserEmail())
    const db = await openDB(dbName, IDB_VERSION);
    sourceBox = md5(sourceBox)
    targetBox = md5(targetBox)
    const tx = db.transaction([sourceBox, targetBox], 'readwrite');
    const sourceStore = tx.objectStore(sourceBox);
    const targetStore = tx.objectStore(targetBox);
    const message = await sourceStore.get(uid);
    if (message) {
        await targetStore.put(message);
        await sourceStore.delete(uid);
    }
    await tx.done;
}


export async function fetchLatestMessage(box) {
    const dbName = md5(StateManager.getUserEmail())
    box = md5(box)
    const db = await openDB(dbName, IDB_VERSION);
    const tx = db.transaction(box, 'readonly');
    const store = tx.objectStore(box);
    const latestMessage = await store.index('uid').getAll(undefined, IDBKeyRange.lowerBound(0, true));
    await tx.done;
    return latestMessage.length > 0 ? latestMessage[latestMessage.length - 1] : null;
}

export async function searchForWordsInBox(box, searchWords) {
    const dbName = md5(StateManager.getUserEmail())
    box = md5(box)
    const db = await openDB(dbName, IDB_VERSION);
    const tx = db.transaction(box, 'readonly');
    const store = tx.objectStore(box);
    const records = await store.getAll();
    const matchingRecords = records.filter(record => {
        const wordsArray = record.words.split(',');
        return searchWords.some(word => wordsArray.includes(word));
    });

    await tx.done;
    return matchingRecords;
}

export async function markMessageAsRead(box, uid) {
    const dbName = md5(StateManager.getUserEmail());
    box = md5(box);
    const db = await openDB(dbName, IDB_VERSION);
    const tx = db.transaction(box, 'readwrite');
    const store = tx.objectStore(box);
    const message = await store.get(uid);
    const flags = message.flags || [];
    const updatedFlags = flags.filter(flag => flag !== "Recent");
    if (!updatedFlags.includes("Seen")) {
        updatedFlags.push("Seen");
    }
    message.flags = updatedFlags;
    await store.put(message);
    await tx.done;
    return true;
}

export async function moveMessage(from, to, uid) {
    const message = await readMessageByUid(from, uid)
    await deleteMessageFromBox(from, uid)
    await addEmailInCache(to, message)
}

export async function copyMessage(from, to, uid) {
    const message = await readMessageByUid(from, uid)
    await addEmailInCache(to, message)
}

export async function parseAndAddEmailInCache(box, mailObject) {
    let parsed = await parseMessageForCache(box, mailObject)
    return addEmailInCache(parsed.box, parsed.cacheObject)
}

export async function updateCache(messageFromBackend) {
    if (!await localDbExists()) {
        return
    }

    const {message, eventType} = messageFromBackend
    switch (eventType) {
        case "newMessage":
            return parseAndAddEmailInCache(message.box, message)
        case "messageDeleted":
            return deleteMessageFromBox(message.box, message.uid)
        case "messageRead":
            return markMessageAsRead(message.box, message.uid)
        case "messageMoved":
            return moveMessage(message.from, message.to, message.uid)
        case "messageCopied":
            return copyMessage(message.from, message.to, message.uid)
        default:
            break;
    }
}


export async function getSearchPaginatedResults(box, searchWords, pageNumber = 1, pageSize = 7) {
    const dbName = md5(StateManager.getUserEmail())
    const storeName = md5(box)
    const db = await openDB(dbName, IDB_VERSION);
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const allRecords = await store.getAll();
    console.log(allRecords)
    let filteredRecords = []
    console.log(searchWords)
    searchWords.forEach((word) => {
        allRecords.forEach((record) => {
            let recordWords = record.words.split(",")
            console.log(recordWords)
            console.log(word)
            if (recordWords.indexOf(word) > -1) {
                filteredRecords.push(record)
            } else {
                console.log(`Word not found in message`)
            }
        })
    })


    const totalResults = filteredRecords.length;
    console.log(totalResults)
    const totalPages = Math.ceil(totalResults / pageSize);
    console.log(totalPages)
    const hasPrevious = pageNumber > 1;
    const hasNext = pageNumber < totalPages;
    const startIndex = (pageNumber - 1) * pageSize;
    let paginatedRecords
    if (hasPrevious || hasNext) {
        paginatedRecords = filteredRecords.slice(startIndex, startIndex + pageSize);
    } else {
        paginatedRecords = filteredRecords
    }

    return {
        mails: paginatedRecords,
        hasNext,
        hasPrevious,
        totalPages,
        currentPage: pageNumber,
    };
}

