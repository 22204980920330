import StandardButton from "../components/basic/StandardButton";
import {useEffect, useState} from "react";
import StandardTextInput from "../components/basic/StandardTextInput";
import styles from "../styles/main.module.css";
import StandardPasswordInput from "../components/basic/StandardPasswordInput";
import {
    extractPrivateKeyFromImage,
    readTextFromFileUpload, userIsLoggedIn
} from "../utils/utils";

import _ from "lodash"

import StandardRadioGroup from "../components/basic/StandardRadioGroup";
import {
    LOGIN_KEY_UPLOAD_TYPES, LOGIN_TYPES,
    VALIDATIONS
} from "../utils/constants";
import StandardFileInput from "../components/basic/StandardFileInput";
import "react-toastify/dist/ReactToastify.css";
import {toast, ToastContainer} from 'react-toastify';
import {signPGPMessage, decryptPrivateKey} from "../business/encryption/pgp";
import {generateSha512Hash} from "../business/hashing";
import StateManager from "../redux/StateManager";
import {sendLoginRequest} from "../server-calls/index";
import {useNavigation} from "../hooks/navigation";
import {generateOrGetDeviceId} from "../cookie";

export default function Login() {

    const {redirect} = useNavigation()
    useEffect(() => {
        userIsLoggedIn().then(isLoggedIn => {
            console.log(isLoggedIn)
            if (isLoggedIn) {
                redirect("/mail")
            }
        })
    }, []);

    const [formOptions] = useState([
        LOGIN_TYPES.keyUpload,
        LOGIN_TYPES.emailPass
    ])
    let [formOption, setFormOption] = useState(LOGIN_TYPES.keyUpload.value);

    let [keyUploadOptions] = useState([
        LOGIN_KEY_UPLOAD_TYPES.asImage,
        LOGIN_KEY_UPLOAD_TYPES.asText
    ])

    let [keyUploadOption, setKeyUploadOption] = useState(LOGIN_KEY_UPLOAD_TYPES.asImage.value)
    let [email, setEmail] = useState("");
    let [passphrase, setPassphrase] = useState("");
    let [privateKey, setPrivateKey] = useState("");
    let [isWorking, setIsWorking] = useState(false)
    let [allDone, setAllDone] = useState(false)
    let [deviceId] = useState(generateOrGetDeviceId())

    function handleEmail(value) {
        email = value
        setEmail(email)
    }

    function handlePassphrase(value) {
        passphrase = value
        setPassphrase(passphrase)
    }

    function handlePrivateKey(value) {
        privateKey = value
        setPrivateKey(privateKey)
    }

    function handleFormOption(value) {
        formOption = value
        setFormOption(formOption)
    }

    function handleKeyUploadTypeOptions(value) {
        keyUploadOption = value
        setKeyUploadOption(keyUploadOption)
    }

    async function handlePrivateKeyUpload(files) {
        try {
            switch (keyUploadOption) {
                case LOGIN_KEY_UPLOAD_TYPES.asText.value:
                    privateKey = await readTextFromFileUpload(files[0])
                    break;
                case LOGIN_KEY_UPLOAD_TYPES.asImage.value:
                    privateKey = await extractPrivateKeyFromImage(files[0], "privateKey.key")
                    break;
                default:
                    privateKey = await readTextFromFileUpload(files[0])
                    break;
            }
            handlePrivateKey(privateKey)
        } catch (e) {
            toast.error("please upload a valid key according to type selected.")
            console.error(e)
        }
    }

    function setBusy() {
        isWorking = true
        setIsWorking(isWorking)
    }

    function setIdle() {
        isWorking = false
        setIsWorking(isWorking)
    }

    async function handleSubmit() {
        try {
            toast.loading("Logging in")
            await performValidations()
            let resp
            switch (formOption) {
                case LOGIN_TYPES.emailPass.value:
                    resp = await emailPassLogin()
                    handlePrivateKey(resp.privateKey)
                    break;
                case LOGIN_TYPES.keyUpload.value:
                    resp = await keyUploadLogin()
                    break;
                default:
                    resp = await emailPassLogin()
                    handlePrivateKey(resp.privateKey)
                    break;
            }

            setIdle()
            StateManager.setIsLoggedIn(true)
            StateManager.setUserEmail(email)
            StateManager.setPrivateKey(privateKey)
            StateManager.setPassphrase(passphrase)
            StateManager.setToken(resp.token)
            redirect("/mail")
        } catch (e) {
            console.error(e)
            setIdle()
            toast.dismiss()
            toast.error(e.message)
        }
    }

    async function emailPassLogin() {
        let accessToken = generateSha512Hash(passphrase)
        return sendLoginRequest({email, accessToken, loginType: formOption, deviceId})
    }

    async function keyUploadLogin() {
        switch (keyUploadOption) {
            case LOGIN_KEY_UPLOAD_TYPES.asText.value:
                return textKeyUploadLogin()
            case LOGIN_KEY_UPLOAD_TYPES.asImage.value:
                return imageKeyUploadLogin()
            default:
                return textKeyUploadLogin()
        }

    }

    async function textKeyUploadLogin() {
        await decryptPrivateKey(privateKey, passphrase)
        let signature = await signPGPMessage(privateKey, passphrase, generateSha512Hash("it's literally nothing"))
        return sendLoginRequest({email, signature, loginType: formOption, deviceId})
    }

    async function imageKeyUploadLogin() {
        await decryptPrivateKey(privateKey, passphrase)
        let signature = await signPGPMessage(privateKey, passphrase, generateSha512Hash("it's literally nothing"))
        return sendLoginRequest({email, signature, loginType: formOption, deviceId})
    }


    async function performValidations() {
        switch (formOption) {
            case LOGIN_TYPES.emailPass.value:
                await validateEmail()
                await validatePassphrase()
                break;
            case LOGIN_TYPES.keyUpload.value:
                await validatePrivateKeyUpload()
                await validatePassphrase()
                break;
            default:
                await validateEmail()
                await validatePassphrase()
                break;
        }

        return Promise.resolve()
    }

    async function validateEmail() {
        if (_.isEmpty(email)) {
            throw new Error("email is required")
        }

        if (!email.toString().includes("@")) {
            throw new Error("please enter a valid email")
        }

        return Promise.resolve()
    }

    async function validatePassphrase() {
        if (_.isEmpty(passphrase) || passphrase.length < VALIDATIONS.PASSPHRASE_LENGTH) {
            throw new Error("passphrase should be minimum 8 characters long")
        }
        return Promise.resolve()
    }

    async function validatePrivateKeyUpload() {
        if (_.isEmpty(privateKey)) {
            throw new Error("private key is required.")
        }
        return Promise.resolve()
    }

    return (
        <main>
            <div className={styles.container}>
                <div style={{width: "400px"}}>
                    <h1>Login</h1>
                    <StandardRadioGroup options={formOptions} onChange={handleFormOption}></StandardRadioGroup>

                    {(formOption === LOGIN_TYPES.keyUpload.value) && (<>
                            <br></br>
                            <label>Key Upload Type</label>
                            <br></br><br></br>
                            <StandardRadioGroup onChange={handleKeyUploadTypeOptions}
                                                options={keyUploadOptions}></StandardRadioGroup>
                            <br></br>
                        </>
                    )}

                    {formOption === LOGIN_TYPES.keyUpload.value && (
                        <StandardFileInput width="100%" name="Private Key"
                                           onChange={handlePrivateKeyUpload}></StandardFileInput>)}

                    <StandardTextInput width="100%" onChange={handleEmail}
                                       placeholder="Email"></StandardTextInput>
                    <StandardPasswordInput onChange={handlePassphrase} width="100%"
                                           placeholder="Passphrase"></StandardPasswordInput>

                    {!allDone && (
                        <StandardButton width="100%" onClick={handleSubmit} disabled={isWorking}
                                        innerText="Login"></StandardButton>)}

                    <br></br><br></br>
                    <a href="/login">Recover Account</a>
                    <br></br>
                    <p>Don't have an account ? Register <a href="/register">here</a></p>
                </div>
                <ToastContainer/>
            </div>
        </main>
    );
}