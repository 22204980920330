import Cookie from "js-cookie"
import {v7} from "uuid"
import _ from "lodash"

export function setDeviceId(deviceId) {
    Cookie.set("deviceId", deviceId, {expires: 365 * 100})
    return deviceId
}

export function getDeviceId() {
    return Cookie.get("deviceId")
}

export function deviceIdExists() {
    let cookie = getDeviceId()
    return !_.isEmpty(cookie);
}

export function generateOrGetDeviceId() {
    if (deviceIdExists()) {
        return getDeviceId()
    } else {
        const deviceId = generateDeviceId()
        setDeviceId(deviceId)
        return getDeviceId()
    }
}

function generateDeviceId(){
    return v7()
}