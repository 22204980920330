import {useNavigate} from 'react-router-dom';

export const useNavigation = () => {
    const navigate = useNavigate();

    const redirect = (url) => {
        navigate(url);
    };

    const goBack = () => {
        navigate(-1);
    };

    const reloadPage = () => {
        navigate(0);
    };
    return {
        redirect,
        goBack,
        reloadPage,
    };
};
