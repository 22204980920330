import React, { useState } from 'react';

const StandardDropdown = ({ options, name, onChange, selectedValue}) => {
    return (
        <select name={name} value={selectedValue} onChange={onChange}>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
    );
};

export default StandardDropdown;
