const BASE_URL = "https://api.privmail.com:2100"
const endPoints = {
    socketUrl: "wss://socket.privmail.com",
    register: {url: `${BASE_URL}/v1/user/register`, method: "POST"},
    login: {url: `${BASE_URL}/v1/user/login`, method: "POST"},
    checkUsernameDuplication: {url: `${BASE_URL}/v1/user/check-duplicate-user`, method: "POST"},
    checkSession: {url: `${BASE_URL}/v1/user/session-status`, method: "GET"},
    logout: {url: `${BASE_URL}/v1/user/logout`, method: "GET"},
    emailListing: {url: `${BASE_URL}/v1/mail/listing`, method: "POST"},
    boxesListing: {url: `${BASE_URL}/v1/mail/list-boxes`, method: "GET"},
    fetchReceiversPublicKeys: {url: `${BASE_URL}/v1/user/fetch-public-keys`, method: "POST"},
    sendMessageHttp: {url: `${BASE_URL}/v1/mail/send`, method: "POST"},
    listingForCache: {url: `${BASE_URL}/v1/mail/listing-for-cache`, method: "POST"},
    fetchMessageBodyForRender: {url: `${BASE_URL}/v1/mail/fetch-body`, method: "POST"},
    fetchAttachmentData: {url: `${BASE_URL}/v1/mail/fetch-attachment-data`, method: "POST"},
    getMessagesCount: {url: `${BASE_URL}/v1/mail/get-messages-count`, method: "GET"},
    markMessageAsRead: {url: `${BASE_URL}/v1/mail/mark-message-as-read`, method: "POST"},
    fetchThread: {url: `${BASE_URL}/v1/mail/fetch-thread`, method: "POST"},
    markDeviceAsComplete: {url: `${BASE_URL}/v1/device/mark-as-complete`, method: "POST"},
    moveMessage: {url: `${BASE_URL}/v1/mail/move-message`, method: "POST"},
    deleteMessage: {url: `${BASE_URL}/v1/mail/delete-message`, method: "POST"}
}

export function getEndpoints() {
    return endPoints
}