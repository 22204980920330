import {
    setIsLoggedIn,
    setPassphrase,
    setPrivateKey,
    setToken,
    setUserEmail,
    cleanState,
    store,
    setCacheMailData,
    setRenderedMailData,
    setMailSortingOrder,
    setMailSortingKey,
    setSearchPlainText,
    setSearchEncryptedWords, setSearchCurrentPage, setIsSearchRecordsRender,
} from './store';

class StateManager {

    static getState() {
        return store.getState();
    }

    static getUserEmail() {
        return this.getState().userEmail;
    }

    static getPrivateKey() {
        return this.getState().privateKey;
    }

    static getPassphrase() {
        return this.getState().passphrase;
    }

    static getToken() {
        return this.getState().token;
    }

    static isLoggedIn() {
        return this.getState().isLoggedIn;
    }

    static cacheMailData() {
        return this.getState().cacheMailData;
    }

    static renderedMailData() {
        return this.getState().renderedMailData;
    }

    static getMailSortingOrder() {
        return this.getState().mailSortingOrder
    }

    static getMailSortingKey() {
        return this.getState().mailSortingKey
    }

    static getSearchPlainText() {
        return this.getState().searchPlainText
    }

    static getSearchEncryptedWords() {
        return this.getState().searchEncryptedWords
    }

    static getSearchCurrentPage() {
        return this.getState().searchCurrentPage
    }

    static getIsSearchRecordRender() {
        return this.getState().isSearchRecordsRender
    }

    static setSearchPlainTextInState(value) {
        return store.dispatch(setSearchPlainText(value))
    }

    static setSearchEncryptedWordsInState(value) {
        return store.dispatch(setSearchEncryptedWords(value))
    }

    static setSearchCurrentPageInState(value) {
        return store.dispatch(setSearchCurrentPage(value))
    }

    static setIsSearchRecordRenderInState(value) {
        return store.dispatch(setIsSearchRecordsRender(value))
    }

    static setMailSortingOrder(order) {
        store.dispatch(setMailSortingOrder(order));
    }

    static setMailSortingKey(key) {
        store.dispatch(setMailSortingKey(key));
    }

    static setUserEmail(userEmail) {
        store.dispatch(setUserEmail(userEmail));
    }

    static setPrivateKey(privateKey) {
        store.dispatch(setPrivateKey(privateKey));
    }

    static setPassphrase(passphrase) {
        store.dispatch(setPassphrase(passphrase));
    }

    static setToken(token) {
        store.dispatch(setToken(token));
    }

    static setIsLoggedIn(isLoggedIn) {
        store.dispatch(setIsLoggedIn(isLoggedIn));
    }

    static cleanState() {
        store.dispatch(cleanState());
    }

    static setCacheMailData(data) {
        store.dispatch(setCacheMailData(data))
    }

    static setRenderedMailData(data) {
        store.dispatch(setRenderedMailData(data))
    }
}

export default StateManager;