import Navigation from "../components/navigation/index"
import Header from "../components/header";
import {useEffect} from "react";
import {userIsLoggedIn} from "../utils/utils";
import {useNavigation} from "../hooks/navigation"


export default function Landing() {
    const {redirect} = useNavigation();
    useEffect(() => {
        checkUserLoggedIn()
    }, []);

    async function checkUserLoggedIn() {
        const isLoggedIn = await userIsLoggedIn();
        if (!isLoggedIn) {
            redirect("/login");
        }else{
            redirect("/mail")
        }
    }

    return (
        <>
            <Header></Header>
            <div className="container-fluid" style={{height: '95vh'}}>
                <div className="row">
                    <div className="col-md-1 col-lg-1 col-xl-1 p-0">
                        <Navigation/>
                    </div>
                </div>
            </div>
        </>
    );
}