import JSZip from "jszip"
import StateManager from "../redux/StateManager";
import _ from "lodash";
import {getMessagesCount, sessionValid} from "../server-calls/index";
import moment from 'moment-timezone';

export function createFile(contentArray, type = "text/plain") {
    let blob = new Blob(contentArray, {type});
    return URL.createObjectURL(blob)
}

export function createImage(src) {
    let image = new Image()
    image.src = src
    return new Promise((resolve, reject) => {
        image.onload = () => {
            resolve(image)
        }

        image.onerror = () => {
            reject(image)
        }

    })
}

export function downloadFile(url, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = url
    document.body.appendChild(link);
    link.click();
}

export function addTextOnImage(text, image) {
    return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        ctx.font = '30px Arial';
        ctx.fillStyle = 'black'; // Set text color to black
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';
        // Add text to the top left corner, not too close to the edge
        const padding = 20; // Padding from the edge
        ctx.fillText(text, padding, padding);
        canvas.toBlob((blob) => {
            resolve(blob);
        });
    })
}

export function base64ToBlob(base64, contentType = 'image/png') {
    const byteCharacters = atob(base64.split(',')[1]); // Decode base64 string
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: contentType});
}

export function base64ToByteArray(base64String) {
    const binaryString = atob(base64String);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }
    return byteArray;
}

export function createZip(text, name) {
    let zip = new JSZip()
    zip.file(name, text)
    return zip.generateAsync({type: "blob"})
}

export function prepareUsernameForKeyImageText(email) {
    const [name, domain] = email.split("@");
    const nameParts = name.split('');
    const length = nameParts.length;
    let response = '';
    if (length > 4) {
        for (let i = 0; i < length; i++) {
            if (i === 0) {
                response += nameParts[i];
            } else if (i === length - 2 || i === length - 1) {
                response += nameParts[i];
            } else {
                response += '*';
            }
        }
    } else if (length < 4) {
        response = nameParts[0] + "*" + nameParts[length - 1];
    } else if (length === 4) {
        response = nameParts[0] + "**" + nameParts[length - 1];
    }
    return response + "@" + domain;
}


export function readTextFromFileUpload(file) {
    console.log(file)
    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const fileContent = event.target.result;
                resolve(fileContent)
            };
            reader.onerror = function (e) {
                reject(e)
            }
            reader.readAsText(file);
        } else {
            return Promise.resolve("")
        }
    })
}

export function extractPrivateKeyFromImage(file, name) {
    return new Promise((resolve, reject) => {
        let jsZip = new JSZip()
        jsZip.loadAsync(file).then(function (zip) {
            console.log(zip)
            zip.files[name].async('string').then(function (fileData) {
                resolve(fileData)
            })
        }).catch((error) => {
            reject(error)
        })
    })
}


export async function userIsLoggedIn() {
    try {
        if (!StateManager.isLoggedIn() || _.isEmpty(StateManager.getToken())) {
            return false
        }
        let boxesRelatedData = await getMessagesCount(StateManager.getToken())
        StateManager.setCacheMailData(boxesRelatedData)
        return true
    } catch (e) {
        return false
    }
}

export function formatDateForMailDisplay(date) {
    const userTimezone = moment.tz.guess();
    const now = moment().tz(userTimezone);
    const inputDate = moment(date).tz(userTimezone);
    const isToday = inputDate.isSame(now, 'day');
    const isYesterday = inputDate.isSame(now.subtract(1, 'day'), 'day');

    if (isToday) {
        return `Today at ${inputDate.format('h:mm A')}`;
    } else if (isYesterday) {
        return `Yesterday at ${inputDate.format('h:mm A')}`;
    } else {
        return inputDate.format('MMMM Do, YYYY [at] h:mm A');
    }
}

export function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export function encodeBase64(text) {
    const utf8Bytes = new TextEncoder().encode(text);
    return btoa(String.fromCharCode(...utf8Bytes));
}

export function base64ToUtf8(base64String) {
    let binaryString = atob(base64String);
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    let decoder = new TextDecoder('utf-8');
    return decoder.decode(bytes);
}

export function parseContactTypeEmail(input) {
    const emailMatches = input.match(/<([^>]+)>/g); // Match all occurrences of emails inside < >
    if (!emailMatches) {
        return "";
    }
    return emailMatches
        .map(match => match.slice(1, -1))
        .join(", ");
}





