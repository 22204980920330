import md5 from "md5"
import {readPublicKey, encryptPGPMessage, decryptPGPMessage} from "../business/encryption/pgp"
import {base64ToUtf8, encodeBase64} from "../utils/utils";
import StateManager from "../redux/StateManager";
import _ from "lodash";
import {HTML_MESSAGE_IDENTIFIER} from "../utils/constants";
import {addEmailInCache} from "../idb";

const attachmentNamesSeparator = md5("ATTACHMENT_NAMES")
const attachmentSeparator = md5("ATTACHMENTS")

export function generateRFC822Message({from, to = [], cc = [], bcc = [], subject, body, attachments, isHtml}) {
    const boundary = 'boundary_' + new Date().getTime();
    let message = `From: ${from}\r\n`;
    if (to.length > 0) {
        message += `To: ${to.join(', ')}\r\n`;
    }
    if (cc.length > 0) {
        message += `Cc: ${cc.join(', ')}\r\n`;
    }
    if (bcc.length > 0) {
        message += `Bcc: ${cc.join(', ')}\r\n`;
    }
    message += `Subject: ${subject}\r\n`;
    message += `MIME-Version: 1.0\r\n`;

    message += `--${boundary}\r\n`;
    if (isHtml) {
        message += `Content-Type: text/html; charset="UTF-8"\r\n\r\n`;
    } else {
        message += `Content-Type: text/plain; charset="UTF-8"\r\n\r\n`;
    }
    message += `${body}\r\n\r\n`;

    if (attachments.length > 0) {
        message += `Content-Type: multipart/mixed; boundary="${boundary}"\r\n\r\n`;
    }

    attachments.forEach((file) => {
        message += `--${boundary}\r\n`;
        message += `Content-Type: ${file.type}; name="${file.name}"\r\n`;
        message += `Content-Disposition: attachment; filename="${file.name}"\r\n`;
        message += `Content-Transfer-Encoding: base64\r\n\r\n`;
        message += `${file.content}\r\n\r\n`;
    });
    message += `--${boundary}--`;
    return message;
}

export async function encryptMessage({
                                         subject,
                                         body,
                                         attachments,
                                         isHtml,
                                         publicKeys
                                     }) {
    let encryptionKeys = []
    for (let i = 0; i < publicKeys.length; i++) {
        encryptionKeys.push(await readPublicKey(publicKeys[i]))
    }

    if (isHtml) {
        body = encodeBase64(body);
        body = `BASE64_HTML_${body}`
    }

    let attachmentNames = ""

    attachments.forEach((attachmentName) => {
        attachmentNames += `${attachmentName}/`
    })

    let encryptionResults = await Promise.all([
        encryptPGPMessage(encryptionKeys, body),
        encryptPGPMessage(encryptionKeys, subject),
        encryptPGPMessage(encryptionKeys, attachmentNames),
    ])

    body = encryptionResults[0]
    subject = encryptionResults[1].replaceAll("\n", "@")
    attachmentNames = encryptionResults[2]
    body = `${body}${attachmentNamesSeparator}${attachmentNames}${attachmentNamesSeparator}${attachmentSeparator}`
    let atcEncryptPromises = []
    attachments.forEach((file) => {
        atcEncryptPromises.push(encryptPGPMessage(encryptionKeys, file.content))
    })

    let atcEncryptResults = await Promise.all(atcEncryptPromises)

    atcEncryptResults.forEach((encAttachment, key) => {
        let attachmentName = md5(attachments[key].name)
        body += attachmentName;
        body += encAttachment
        body += attachmentName;
    })
    return {body, encryptedSubject: subject}
}

export const MailFieldsForCache = {
    uid: "uid",
    from: "from",
    to: "to",
    isEncrypted: "isEncrypted",
    subject: "subject",
    body: "body",
    attachmentNames: "attachmentNames",
    references: "references",
    isReplyTo: "isReplyTo",
    words: "words"
}

export async function decryptMessageBody(message) {
    if (message.isEncrypted && !_.isEmpty(message.body)) {
        return decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), message.body)
    } else {
        return message.body
    }
}

export async function decryptMessageSubject(message) {
    if (message.isEncrypted && !_.isEmpty(message.subject)) {
        let subject = message.subject
        subject = subject.replaceAll("@", "\n")
        return decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), subject)
    } else {
        return message.subject
    }
}

export async function decryptMessageAttachmentNames(message) {
    if (message.isEncrypted && !_.isEmpty(message.attachmentNames)) {
        return decryptPGPMessage(StateManager.getPrivateKey(), StateManager.getPassphrase(), message.attachmentNames)
    } else {
        return message.attachmentNames
    }
}

export async function parseMessageForCache(box, mailObject) {
    if (_.isEmpty(mailObject.from)) {
        return
    }
    let [subject, body, attachmentNames] = await Promise.all([
        decryptMessageSubject(mailObject),
        decryptMessageBody(mailObject),
        decryptMessageAttachmentNames(mailObject),
    ])

    if (body.startsWith(HTML_MESSAGE_IDENTIFIER)) {
        body = base64ToUtf8(body.split(HTML_MESSAGE_IDENTIFIER)[1])
        body = extractTextFromHTML(body)
    }

    body = body.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');
    subject = subject.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');


    let words
    words = [...subject.split(" "), ...attachmentNames.split("/"), ...body.split(" ")]

    words = words.filter(item => item !== "")

    words = [...new Set(words)]

    let formattedWords = []
    words.forEach((word) => {
        formattedWords.push(md5(word.toLowerCase()))
    })
    formattedWords.push(md5(mailObject.from))

    let cacheObject = {
        ...mailObject,
        words: formattedWords.join(",")
    }

    return {box, cacheObject}
}

function extractTextFromHTML(htmlString) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
}




