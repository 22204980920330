import io from 'socket.io-client';
import {getEndpoints} from "../utils/server-endpoints";
import StateManager from "../redux/StateManager";

export function initializeSocket() {
    const socket = io(getEndpoints().socketUrl, {
        auth: {
            token: StateManager.getToken()
        }
    });
    socket.on("connect", () => {
        console.log("Connected to socket server");
        const queueName = `${StateManager.getUserEmail()}_${StateManager.cacheMailData().device.deviceId}`
        console.log(queueName)
        socket.emit("joinRoom", queueName);
        console.log("Room joined")
    });

    return socket;
}
